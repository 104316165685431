import "./src/styles/global.css"

import ReactDOM from "react-dom/client"
// import Layout from "./src/components/layout"

export const replaceHydrateFunction = () => {
    return (element, container) => {
        console.log("replaceHydrateFunction")
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}
